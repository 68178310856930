

.productIndexContainer {
  width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}


.listingPageContainer {
  display: flex;
  justify-content: space-between;
}

.listingPageImage {
  display: flex;
  /* flex-direction: column; */
}

.imageAndreview{
  display: flex;
  flex-direction: column;
}
.innerContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
}


.imagesContainer > img {
  width: 600px;
  height: 500px;
  border-radius: 10px;
}

.arrowButton {
  background-color: transparent;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  box-shadow: 0 4px 20px rgb(34 34 34 / 15%);
  cursor: pointer;
  margin-left: 17px;
  margin-right: 17px;
}

.navArrow {
  display: block;
  font-size: 21px;
  text-align: center;
  padding-left: 0;
  padding-bottom: 0;
  margin-top: 5px;
}

/* .navArrow > svg {
  vertical-align: top;
} */

.carouselItem > img,
.carouselItem1 > img {
  width: 72px;
  height: 72.7px;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.carouselItem2 > img {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  cursor: pointer;
}
.carouselItemsList {
  display: flex;
  flex-direction: column;
}

.rightContainer {
  margin-left: 16px;
}

.itemName {
  font-size: 30px;
  /* font-family: "Playfair Display", serif; */
  font-family: "Cormorant", serif;
  font-weight: lighter;
  letter-spacing: 0.5px;
  color: #4d4d4d;
  padding-bottom: 15px;
}

.itemPrice {
  font-size: 25px;
  font-weight: bold;
  font-family: "Yantramanav", sans-serif;
  padding-bottom: 15px;
}

.itemQty {
  font-family: "Yantramanav", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 13px;
  padding-bottom: 5px;
}

.optionBar {
  width: 350px;
  height: 40px;
  border-radius: 6px;
  background: #ffffff;
  border-color: rgba(34, 34, 34, 0.15);
  border-style: solid;
  margin-bottom: 20px;
  cursor: pointer;
  box-shadow: 0 1px 6px 0 rgb(34 34 34 / 15%);
  padding-left: 13px;
  -moz-appearance: none; 
  -webkit-appearance: none; 
  appearance: none; 
  background: url(./../../../public/img/down-caret.svg);
  background-position: right center; 
  background-size: 2.2em;
  background-repeat: no-repeat;

  /* padding-right: 2rem; */
  /* background: url(/img/down-caret.svg); */
}




.AddCartButton {
  width: 350px;
  height: 40px;
  color: white;
  background-color: black;
  border: none;
  cursor: pointer;
  border-radius: 24px;
  margin-bottom: 20px;
}

.AddCartButton:hover {
  transform: scale(1.03);
  transition: transform ease-in 200ms;
}

.shipping,
.description {
  background-color: transparent;
  width: 350px;
  height: 40px;
  cursor: pointer;
  border-radius: 24px;
  letter-spacing: 2px;
  margin-bottom: 20px;
  border: none;
}

.shipping:hover,
.description:hover {
  border: none;
  background-color: #dcdcdc;
}

.descriptionContent {
  margin-bottom: 20px;
  display: block;
  padding-left: 12px;
  padding-right: 12px;
  font-family: "Yantramanav", sans-serif;
  font-weight: 100;
  line-height: 20px;
}

.arrowDownButton {
  padding-right: 4px;
}

.descriptionTab,
.shippingTab {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.descriptionTitle,
.shippingTitle {
  font-size: 14px;
  font-family: "Yantramanav", sans-serif;
}

.returnStatus,
.returnDuration {
  padding-left: 12px;
  padding-right: 12px;
}

.returnText {
  font-family: "Yantramanav", sans-serif;
  font-weight: 100;
  line-height: 20px;
  font-size: 13px;
}

.returnText2 {
  font-family: "Yantramanav", sans-serif;
  line-height: 32px;
  font-size: 21px;
  letter-spacing: 0.35;
  margin-bottom: 25px;
  margin-top: 5px;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-position: under;
}

.returnContainer {
  display: flex;
  justify-content: space-between;
}

