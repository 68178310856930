.cartItems {
  width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
  min-height: 1000px;
}

.cartHeader {
  display: flex;
  justify-content: space-between;
}

.header1 {
  font-family: "Cormorant", serif;
  font-size: 35px;
  font-weight: bold;
  padding: none;
}

.header2 {
  background-color: transparent;
  width: 130px;
  height: 40px;
  cursor: pointer;
  border-radius: 24px;
  padding-top: 12px;
  padding-left: 11px;
  letter-spacing: 0.5px;
}

.header2:hover {
  border: none;
  background-color: #dcdcdc;
}

.warning {
  background-color: #d7e6f5;
  height: 50px;
  border-radius: 13px;
  margin-bottom: 20px;
  margin-top: 20px;
  letter-spacing: 1px;
  display: flex;
}

.handImage > img {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-left: 5px;
}

.r {
  display: flex;
  padding-top: 13px;
}

.protection {
  font-weight: bold;
  font-size: 13px;
  font-family: "Yantramanav", sans-serif;
  letter-spacing: 0.5px;
  margin-top: 18px;
}

.protectionDes {
  font-size: 12px;
  font-family: "Yantramanav", sans-serif;
  margin-top: 18px;
}

.cartContainer {
  display: flex;
  justify-content: space-between;
}

.cartItemImage > img {
  height: 180px;
  width: 230px;
  border-radius: 6px;
}

.giftCheckboxText {
  font-family: "Yantramanav", sans-serif;
  margin-top: 10px;
}

.giftTextarea > textarea {
  width: 350px;
  height: 70px;
  margin-top: 10px;
  font-family: "Yantramanav", sans-serif;
  resize: none;
  border: none;
  border-radius: 12px;
  padding-left: 10px;
  box-shadow: 0 4px 20px rgb(34 34 34 / 15%);
}

.cartItemsContainer {
  width: 800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-right: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
}

.cartItemDetails {
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
  width: 530px;
  margin-right: 12px;
}

.cartItemDetails > li,
.cartItemDetails > li > span {
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
}

.cartProductName {
  letter-spacing: 0.5px;
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-top: 2px;
}

.pdName {
  padding-left: 0;
}
.removeCart {
  background-color: transparent;
  width: 80px;
  height: 30px;
  cursor: pointer;
  border-radius: 24px;
  padding-top: 8px;
  padding-left: 13px;
  letter-spacing: 0.5px;
  margin-top: 20px;
  font-size: 15px !important;
}

.removeCart:hover {
  border: none;
  background-color: #dcdcdc;
}

.cartOptionBar {
  width: 70px;
  height: 40px;
  border-radius: 6px;
  background: #ffffff;
  border-color: rgba(34, 34, 34, 0.15);
  border-style: solid;
  margin-bottom: 20px;
  cursor: pointer;
  box-shadow: 0 1px 6px 0 rgb(34 34 34 / 15%);
  padding-left: 9px;
  -moz-appearance: none; 
  -webkit-appearance: none; 
  appearance: none; 
  background: url(./../../../public/img/down-caret.svg);
  background-position: right center; 
  background-size: 2.2em;
  background-repeat: no-repeat;
}

.cartPrice {
  margin-top: 2px;
}

.checkOutContainer {
  width: 370px;
  height: 320px;
  box-shadow: 0 4px 20px rgb(34 34 34 / 15%);
  border-radius: 18px;
}

.checkoutDetails {
  margin-left: 13px;
  margin-right: 20px;
  margin-top: 20px;
}

.shoppingDetail {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  width: 340px;
  height: 35px;
  padding-top: 6px;
  margin-bottom: 5px;
}

.shoppingDetail1 {
  display: flex;
  justify-content: space-between;

  width: 340px;
  height: 30px;
  padding-top: 6px;
}

.detailText {
  font-family: "Yantramanav", sans-serif;
  font-size: 18px;
}

.detailText2 {
  font-family: "Yantramanav", sans-serif;
  font-weight: 100;
  font-size: 18px;
}

.checkout {
  width: 250px;
  height: 40px;
  border-radius: 40px;
  border: none;
  letter-spacing: 0.5px;
  color: white;
  background-color: black;
  margin-left: 70px;
  margin-top: 40px;
}
