.registerButton{
    border-radius: 25px;
    background-color: grey;
    border: grey;
    width: 250px;
    height: 40px;
    color: white;
    margin-top:10px;
    margin-left: 50px;
    font-size: 15px;
    letter-spacing: 0.5px;
    margin-top: 25px;
}

.SignupTitle {
    font-size: 25px;
    font-weight: 500;
    padding: 70px 0 10px 17px;
  
    letter-spacing: 3px;
 
    font-family: "Graphik Webfont",-apple-system,"Helvetica Neue","Droid Sans",Arial,sans-serif;
}

.SignupDes {
    font-size: 16px;
    font-style: 300;
    letter-spacing: 1px;
    padding-left: 17px;
    padding-bottom: 15px;
    font-family: "Graphik Webfont",-apple-system,"Helvetica Neue","Droid Sans",Arial,sans-serif;
}

.newEmail, .newPassword, .firstName {
    border-radius: 15px;
    width: 320px;
    height: 40px;
    margin-left: 12px;
    border: none;
    box-shadow: inset 0 0 0 2px lightgrey;
    
}

.error {
    color:#A61A2E;
    padding-left: 20px;
    padding-top: 5px;
}
