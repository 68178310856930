.reviewHeader{
  display: flex;
}

.review-notice{
margin:7px 0 12px 0;
letter-spacing: 0.5px;
color: grey;
text-align: center;

}

.reviewTitle{
  font-size: 30px;
  font-family: "Cormorant", serif;
  padding-top: 60px;
 
  text-align: center;
  padding-bottom: 10px;
  line-height: 32px;
  color: #4d4d4d;
  letter-spacing: 1.2px;
}

.reviewLine, .reviewLine2{
  border-bottom: 0.5px solid grey;
 width: 350px;
 margin-bottom: 23px;
}

.reviewTextArea{
  resize: none;
  width: 400px;
  height: 200px;
  font-size: 15px;
  font-family: "Yantramanav", sans-serif;
  letter-spacing: 1px;
  border:1px solid rgba(34, 34, 34, 0.15);
  /* justify-content: center; */
  box-shadow: 0 1px 6px 0 rgb(34 34 34 / 15%);
  border-radius: 6px;
  padding-left:10px;
  margin-left:20px
}

.errorMessage{
  color:red;
  font-size: 18px;
  margin-left: 24px;
  font-family: "Yantramanav", sans-serif;
}

.reviewForm{
  display: flex;
  margin-top: 20px;
  font-family: "Yantramanav", sans-serif;
}

.reviewFormRight{
  margin-left: 70px;
  letter-spacing: 1px;
}

.reviewSubmitButton{
  width: 200px;
  height: 35px;
  color: white;
  background-color: black;
  border: none;
  cursor: pointer;
  border-radius: 24px;
  margin-top: 18px;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
  /* margin-left: 95px; */
}

.askReviewTitle{
  margin-bottom: 13px;
  font-size:20px;

}

.rateItem{
  margin-top: 18px;
  font-size: 20px;
  margin-bottom: 5px;
}

.suggestedReview{
  font-size: 15px;
  color:grey;
 
}
.starRating{
  font-size: 20px;
  cursor: pointer;
  margin-top: 11px;
  color:grey
}

.starRating:hover{
  color:black
}


.reviewSection, .editSection{
  margin-top: 20px;
  font-family: "Yantramanav", sans-serif;
}

.reviewByUser{
  color:gray;
  padding-top:10px;
  line-height: 20px;
}

.editByUser{
  color:gray;
  margin-top:5px;
  padding:10px 5px 10px 5px ;
  line-height: 20px;
  font-size: 15px;
  border:1px solid rgba(34, 34, 34, 0.15);
  /* justify-content: center; */
  box-shadow: 0 1px 6px 0 rgb(34 34 34 / 15%);
  resize: none;
  width: 900px;
  height: 100px;
  font-family: "Yantramanav", sans-serif;
  letter-spacing: 1px;
}



.reviewStarRating{
 font-size: 15px;
 padding-top: 2px;
}

.starIcon{
font-size: 20px;
padding-left: 0;
padding-right: 8px;
}



.writer{
  display: flex;
}

.reviewDate{
  margin-right: 5px;
  margin-top: 4px;
}

.reviewUser{
  margin-top: 4px;
  
}

.deleteReview{
  background: black;
  color:white;
  border: none;
  border-radius: 25px;
  margin-left: 25px;
  height: 20px;
  font-size: 11px;
 
}

.editReview{
  background:white;
  color:black;
  border: 1px solid black;
  border-radius: 25px;
  margin-left: 7px;
  height: 20px;
  font-size: 11px;
 
}


.userAndDelete{
  display: flex;
}

.review{
  margin-bottom: 50px;
  margin-left: 18px;
  margin-right:18px
}

