.loginForm > *  {
    display: flex;
    flex-direction: column;
  
}

.loginTitle {
    font-size: 25px;
    font-weight: 500;
    margin-bottom:10px;
    margin-top: 10px;
    margin-left: 17px;
    letter-spacing: 3px;
    font-family: "Graphik Webfont",-apple-system,"Helvetica Neue","Droid Sans",Arial,sans-serif;
}

label {
    font-family: "Graphik Webfont",-apple-system,"Helvetica Neue","Droid Sans",Arial,sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 18px;
    display: block;
    margin:10px;
    margin-left: 18px;
    letter-spacing: 2px;
}


.submitButton {
    background-color: black;
    color:white;
    border-radius:25px;
    width: 200px;
    height: 30px;
}

.email, .password{
    border-radius: 15px;
    width: 320px;
    height: 40px;
    margin-left: 12px;
    border: none;
    box-shadow: inset 0 0 0 2px lightgrey;
    
}

.submitButton {
    width: 250px;
    height: 40px;
    margin-top: 25px;
    margin-left: 50px;
    border: black;
    font-size: 15px;
    letter-spacing: 1px;
}

.demoUser {
    width:250px;
    height: 40px;
    border-radius: 25px;
    margin-top:10px;
    margin-left: 50px;
    border: none;
    font-size: 15px;
    letter-spacing: 0.5px;
}

.privacyPolicy{
    margin-top: 30px;
    margin-left:20px;
    margin-right: 20px;
    font-size: 13px;
    line-height: 1.5;
    color:grey;
    font-family: "Graphik Webfont",-apple-system,"Helvetica Neue","Droid Sans",Arial,sans-serif;
}

.errorLogin {
    color:#A61A2E;
    text-align: center;
    padding-top: 20px;
}