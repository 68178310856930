.homepage {
  height: 100%;
}

.textContainer {
  background-color: #fdebd2;
  height: 160px;
}

.displays {
  display: flex;
  justify-content: space-between;
  justify-content: center;
}

.display > img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  padding: 20px;
  display: block;
}

.displaysTitle {
  text-align: center;
  padding-top: 25px;
  padding-bottom: 12px;
  font-size: 32px;
  letter-spacing: 1px;
  font-family: "Cormorant", serif;
}

.suggestionFirstrow,
.suggestionSecondrow {
  width: 100%;
  display: flex;
  gap: 15px;
  padding-bottom: 4px;
}

.list > img {
  width: 230px;
  height: 170px;
  border-radius: 25px;
  padding-top: 10px;
  cursor: pointer;
}

.priceBadge {
  background-color: white;
  display: inline-block;
  margin-left: 10px;
  border-radius: 25px;
  height: 30px;
  width: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.display:hover {
  transform: scale(1.1);
  transition: transform ease-in 200ms;
  cursor: pointer;
  text-decoration: underline;
}

.text1,
.text2,
.text3,
.text4,
.text5,
.text6 {
  font-family: "Yantramanav", sans-serif;
}

.text1 {
  padding-left: 25px;
}

.text2,
.text3 {
  padding: 33px;
}

.text4,
.text5 {
  padding: 53px;
}

.text6 {
  padding: 60px;
}

.featureItems {
  padding-top: 120px;
  width: 1200px;
  margin: 0 auto;
  cursor: pointer;
}

.itemsTitle {
  font-size: 16px;
  letter-spacing: 1px;
  font-family: "Yantramanav", sans-serif;
  font-weight: bold;
  display: block;
}

.discoverItemsTitle > h1 {
  font-size: 27px;
  font-family: "Yantramanav", sans-serif;
  font-weight: bold;
  padding-top: 20px;
  letter-spacing: 1px;
  padding-bottom: 25px;
}

.discoverItemsContainer,
.tabContaner,
.tabContaner {
  padding-top: 20px;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.tabLists {
  display: flex;
  justify-content: space-around;
  font-family: "Yantramanav", sans-serif;
  font-size: 20px;
  color: #696969;
  padding-bottom: 10px;
  letter-spacing: 1px;
}

.tabLists > li {
  cursor: pointer;
}

.tabPanel {
  padding-top: 20px;
  width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: minmax(150px, auto);
  gap: 10px;
  border-top: 2px solid lightgrey;
}

.item1 > img,
.item4 > img {
  width: 400px;
  height: 400px;
  cursor: pointer;
}

.item2 > img,
.item3 > img {
  /* width: 180px; */
  width: 100%;
  height: 193px;
  cursor: pointer;
}

.item1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}

.item4 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
}
/* 
.footer {
  background-color: #2f466c;
  height: 80px;
  color: white;
  font-family: "Yantramanav", sans-serif;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.leftFooter {
  min-width: 350px;
  display: flex;
  padding-left: 33px;
  justify-content: space-evenly;
}

.rightFooter {
  min-width: 350px;
  display: flex;
  justify-content: flex-start;
  justify-content: space-evenly;
  padding-right: 13px;
} */

/* .gitHub,
.linkedin {
  font-size: 22px;
} */

/* .flag > img {
  height: 14px;
  width: 14px;
  border-radius: 50%;
} */

.tabPanel2 {
  padding-top: 20px;
  width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: minmax(150px, auto);
  gap: 10px;
  border-top: 2px solid lightgrey;
}
.item5 > img,
.item8 > img {
  width: 400px;
  height: 400px;
  cursor: pointer;
}

.item6 > img,
.item7 > img {
  width: 100%;
  height: 193px;
  cursor: pointer;
}

.item5 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}

.item8 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
}

.tabPanel1 {
  padding-top: 20px;
  width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: minmax(150px, auto);
  gap: 10px;
  border-top: 2px solid lightgrey;
}
.item9 > img,
.item12 > img {
  width: 400px;
  height: 400px;
  cursor: pointer;
}

.item10 > img,
.item11 > img {
  width: 100%;
  height: 193px;
  cursor: pointer;
}

.item9 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}

.item12 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
}

.tabPanel3 {
  padding-top: 20px;
  width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: minmax(150px, auto);
  gap: 10px;
  border-top: 2px solid lightgrey;
}
.item13 > img,
.item16 > img {
  width: 400px;
  height: 400px;
  cursor: pointer;
}

.item14 > img,
.item15 > img {
  width: 100%;
  height: 193px;
  cursor: pointer;
}

.item13 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}

.item16 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
}
