.exitButton {
    background-color: transparent;
    color: white;
    border: none;
    position: absolute;
    left:400px;
    top:0px;
    font-size: 40px;
}

.moveRegisterButton {
    border-radius: 25px;
    background-color: white;
    border: 1.5px solid black ;
    width:80px;
    height: 35px;
    letter-spacing: 2px;
    position: relative;
    left:250px;
    top: 40px;
}